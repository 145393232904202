
import {
    defineComponent,
    inject,
    computed,
    onMounted,    
    onBeforeMount,
    reactive,
    ref,
    ComputedRef,
} from 'vue'
import {
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonPage,
} from '@ionic/vue'
import Swal from 'sweetalert2'
import moment from 'moment'
import * as yup from 'yup'
import { useField } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'

import { useRouter } from 'vue-router'

export default defineComponent({
    components: {
        IonContent,
        IonInput,
        IonItem,
        IonLabel,
        IonSelect,
        IonSelectOption,
        IonPage,
    },
    setup() {
        const authStore: any = inject('authState')
        const userData = computed(() => authStore.state.userData)

        const configurationStore: any = inject('configurationStore')
        const mobileValidationFormData = reactive(
            configurationStore.state.phone.mobileValidationFormData
        )
        const newMobileFormData = reactive(
            configurationStore.state.phone.newMobileFormData
        )

        const signupStep2FormData = reactive(
            configurationStore.state.signupStep2FormData
        )

        const provinces = computed(
            () => configurationStore.state.details.provinces
        )
        const genders = computed(() => signupStep2FormData.genders)
        const countries = computed(
            () => configurationStore.state.details.countries
        )

        const newMobileIsSelected = ref(false)
        const mobileValidationIsSelected = ref(false)
        const register2 = ref(false)


        // TODO: Extract validators to their own file
        const { errorMessage: nickError, value: nickname } = useField<string>(
            'nickname',
            yup.string().required(i18n.global.t('FIELD_REQUIRED'))
        )
        const { errorMessage: genderError, value: gender } = useField<string>(
            'gender',
            yup.string().required(i18n.global.t('FIELD_REQUIRED'))
        )
        const { errorMessage: provinceError, value: province } =
            useField<number>(
                'province',
                yup.number().required(i18n.global.t('FIELD_REQUIRED'))
            )
        const { errorMessage: postalCodeError, value: postalCode } =
            useField<string>(
                'postalCode',
                yup
                    .string()
                    .required()
                    .matches(authStore.state.userData.residenceCountry.elementId == 0 ? /^[0-9]+$/ : /^\d{4}-\d{3}$/, i18n.global.t('WRONG_POSTALCODE_FORMAT'))
                    .min(5, i18n.global.t('WRONG_POSTALCODE_FORMAT'))
                    .max(authStore.state.userData.residenceCountry.elementId == 0 ? 5 : 9, i18n.global.t('WRONG_POSTALCODE_FORMAT'))
            )

        const register2FormValid: ComputedRef<boolean> = computed(
            (): boolean =>
                !!nickname.value &&
                !nickError.value &&
                !!gender.value &&
                !genderError.value &&
                Number(province.value) >= 0 &&
                !provinceError.value &&
                !!postalCode.value &&
                !postalCodeError.value
        )

        onMounted(async () => {
            if (authStore.state.userData.mobileNum != null) {
                configurationStore.resendMobileConfirmation()
            }
        })

        onBeforeMount(async () => {
            await authStore.getUserDetails();
            const provinces = await configurationStore.getElementsWithFilter(authStore.state.userData.residenceCountry.secondaryValue)
            if (!provinces) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error recuperando provincias',
                })
            }
            const countries = await configurationStore.getElements(
                configurationStore.state.details.Countries
            )
            if (!countries) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error recuperando países',
                })
            }
        })

        const router: any = useRouter()
        const goTo = (name: any) => {
            router.push({ name: name })
        }

        const newMobileFormSubmit = async() => {
            configurationStore.state.phone.newMobileFormData.showSuccess = false
            configurationStore.state.phone.newMobileFormData.showError = false
            if (configurationStore.state.phone.newMobileFormData.mobile != null && 
                configurationStore.state.phone.newMobileFormData.mobile.toString().length != 9) {
                    configurationStore.state.phone.newMobileFormData.showError = true
                    configurationStore.state.phone.newMobileFormData.errorMessage = 'tester_mobile_not_valid'
            } else {
                const data = {
                    mobile: configurationStore.state.phone.newMobileFormData.mobile,
                }
                const success = await configurationStore.newMobile(data)
                if (success === true) {
                    authStore.getUserDetails()
                    Swal.fire({
                        icon: 'success',
                        title: i18n.global.t('tester_mobile_validated'),
                        text: i18n.global.t('tester_email_validate_mobile')
                    })
                }
            }
        }

        const setErrorMessage = (errorCode: any) => {
            switch (errorCode) {
                case 'nickname_is_already_in_use':
                    return i18n.global.t('tester_personal_data_nickname_error');
                case 'tester_pin_code_error':
                    return i18n.global.t('tester_pin_code_error');
                case 'tester_mobile_not_valid':
                    return i18n.global.t('tester_mobile_not_valid');
                case 'registration_mobile_fraud':
                    return i18n.global.t('tester_duplicate_mobile_error');
                case 'default_error_message':
                default:
                    return i18n.global.t('tester_default_error');
            }
        }

        const checkDateInputs = (dateValue: any) => {
            let userDay = parseInt(dateValue.day, 10);
            let userMonth = parseInt(dateValue.month, 10);
            let userYear = parseInt(dateValue.year, 10);
            let currentYear = new Date().getFullYear();
            if ((currentYear - userYear) >= 100 || userMonth < 1 || userDay < 1) {
                return true
            } else {
                return false
            }
        }

        const resendMobileConfirmation = () => {
            const success = configurationStore.resendMobileConfirmation()
            if (success) {
                Swal.fire({
                    icon: 'success',
                    title: i18n.global.t('tester_mobile_validation_resend'),
                    text: i18n.global.t('tester_email_validate_mobile_now')
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t('Error_Try_Again'),
                })
            }
        }

        const mobileValidationFormSubmit = async () => {
            configurationStore.state.phone.mobileValidationFormData.showSuccess =
                false
            configurationStore.state.phone.mobileValidationFormData.showError =
                false
            //if form valid
            const data = {
                validationCode:
                    configurationStore.state.phone.mobileValidationFormData
                        .mobileValidationCode,
            }
            const success = await configurationStore.validateMobile(
                authStore.state.userData.identifier,
                data
            )
            if (success) {
                authStore.getUserDetails()
                if (authStore.state.userData.profileCompleted) {
                    goTo('tests.index')
                    return
                }
                authStore.state.userData.mobileValidated = true
            }
        }

        const signupSubmitStep2 = async () => {
            if (!register2FormValid.value) return

            signupStep2FormData.showSuccess = false
            signupStep2FormData.showError = false
            signupStep2FormData.showInvalidDate = false
            const bornDate = moment(
                `${signupStep2FormData.birthday.year}-${signupStep2FormData.birthday.month}-${signupStep2FormData.birthday.day}`,
                'YYYY-MM-DD'
            )
            if (!bornDate.isValid() || checkDateInputs(signupStep2FormData.birthday)) {
                signupStep2FormData.showInvalidDate = true
                return
            }
            if (moment().diff(bornDate, 'years') < 16) {
                Swal.fire({
                    icon: 'warning',
                    title: i18n.global.t('tester_age_min'),
                    text: i18n.global.t('tester_age_min_msg'),
                })
                return
            }
            const data = {
                nickname: nickname.value,
                gender: gender.value,
                province: {
                    elementId: province.value,
                },
                bornDate: bornDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                postalCode: postalCode.value,
                extraData: 'bypassProfile',
            }
            const succes = await configurationStore.register2(data)
            if (!succes) {
                return
            }
            if(authStore.state.userData.referralCode == "adsalsa") authStore.sendAdSalsaPixel()
            authStore.getUserDetails()
        }

        return {
            userData,
            mobileValidationFormData,
            newMobileFormData,
            newMobileIsSelected,
            mobileValidationIsSelected,
            newMobileFormSubmit,
            resendMobileConfirmation,
            mobileValidationFormSubmit,
            provinces,
            genders,
            signupStep2FormData,
            signupSubmitStep2,
            register2,
            countries,
            nickname,
            nickError,
            gender,
            genderError,
            province,
            provinceError,
            postalCode,
            postalCodeError,
            register2FormValid,
            goTo,
            setErrorMessage,
            checkDateInputs
        }
    },
})
